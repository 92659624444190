<template>
  <div class="container">
    <div class="page-title">常用图库</div>
    <div class="content">
      <div class="title">常用图片库</div>
      <div class="image-box">
        <div v-for="(item,index) in bigTypes" :key="index" class="image-item" @click="query(item.id)">
          <div class="image-src">{{item.name}}类</div>
          <div class="image-notice">点击查看</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        textarea: '',
        orderInfo: '',
        total: 0,
        pageSize: 10,
        currentPage:1,
        listLoading: false,
        bigTypes:[]
      };
    },
    activated () {
      this.queryBigTypes(); //查询大类
    },
    methods: {
      queryBigTypes(){
        this.$http.post(`/menuName/findFirstName`, {}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====queryOptions',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.bigTypes = res.data.data;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      query(firstId){
        this.$router.push({ name: "specificList",query:{firstId} });
      },
    },
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    /*width: 90%;*/
    overflow: auto;
    .title {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 30px;
      /*color: red;*/
      font-size: 26px;
    }
    .image-box {
      display: flex;
      flex-wrap: wrap;
      margin: 0 50px;
      .image-item {
        display: flex;
        flex-direction: column;
        margin: 0 40px 20px 0;
        cursor:pointer;
        .image-src {
          width: 300px;
          height: 150px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 40px;
          border: 1px solid #333;
        }
        .image-notice {
          color: red;
        }
      }
    }
    .menu {
      margin: 0 auto;
      width: 90%;
      display: flex;
      flex-direction: column;
      .menu-title {
        font-size: 16px;
        text-align: left;
        margin-bottom: 10px;
      }
    }
  }
</style>
